import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby"
import Layout from "../components/Layout";
import Container from "@material-ui/core/Container";
import ContentCardsCampus from "../components/ContentCardsCampus";
import HeaderSection from "../components/HeaderSection";
import SEO from "../components/SEO";

const AboutPageTemplate = ({
  title,
  heading,
  description,
  team,
  location,
}) => {
  return (

    <Container maxWidth="lg" >
      <HeaderSection
        header={heading}
        subheader={description}
        location={location}
      />
      <HeaderSection
        header={team.title}
        subheader={team.description}
        location={location}
      />

      {/* <Container
        maxWidth="lg"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }} 
      >*/}
      <ContentCardsCampus content={team} />
    </Container>

  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  team: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const AboutPage = (props) => {
  const { data, location } = props;
  const { frontmatter } = data?.markdownRemark;
  return (
    <Layout location={location}>
      <SEO
        title={frontmatter.title}
        location={location}
        description={
          "Small and familiar coding school that focuses on your learning objectives."
        }
      />
      <AboutPageTemplate
        title={frontmatter.title}
        heading={frontmatter.heading}
        description={frontmatter.description}
        team={frontmatter.team}
        location={location}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        description
        team {
          title
          description
          blurbs {
            image {
              childImageSharp {
                  fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }              
              }
            }
            text
            title
            blurbs {
              title
              content
            }
          }
        }
      }
    }
  }
`;
